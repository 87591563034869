.curatedList {
  margin-top: 1rem;
}

.playlistItem {
  position: relative;
  background-color: transparent;
  transition: all 0.5s linear;
}
.playlistItem img {
  height: 100%;
}

.playlistItem::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: all 0.5s linear;
}
.playlistItem:hover::after {
  transition: all 0.4s ease-out;
  background-color: rgba(0, 0, 0, 0.5);
}
.itemDetails {
  margin: 0 auto;
  position: absolute;
  top: 30%;
  left: 20%;
  transform: translate(-50% -50%);
  flex-direction: column;
  align-items: center;
  z-index: 201;
  transition: all 0.52s linear;

  display: none;
}
.playlistItem:hover .itemDetails {
  display: flex;
}
.itemDetails h2 {
  color: var(--whiteColor);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: var(--secondaryFont);
  line-height: 1.5;
  text-align: center;
}
.itemLink {
  margin-top: 3.5rem;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
}
.itemLinkk a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteColor);
  font-family: var(--secondaryFont);
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 24px;
  transition: var(--transition);
  border: 2px solid var(--whiteColor);
  z-index: 1;
}
.streamLink {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 45px;
  border: 1px solid var(--whiteColor);
  background: transparent;
  color: var(--whiteColor);
  font-family: var(--secondaryFont);
  cursor: pointer;
  transition: var(--transition);
}
.streamLink:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}

@media screen and (max-width: 768px) {
  .curatedList {
    display: flex;
    flex-direction: flex;
    align-items: center;
    gap: 1rem;
  }
  .curatedList div {
    margin-block: 1rem;
  }
  .playlistItem {
    margin-left: 5%;
  }
}

@media screen and (max-width: 375px) {
  .curatedList img {
    width: 300px;
    margin-left: 2%;
  }
}

@media screen and (min-width: 769px) {
  .curatedList {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
  }
  .curatedList img {
    width: 350px;
  }
}
@media screen and (min-width: 1440px) {
  .curatedList {
    justify-content: center;
  }
}
