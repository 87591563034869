.astroloudMusic {
  background: url("../assets/images/HOME.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: var(--transition);
  object-fit: cover;
}

.songsContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 400px));
  justify-content: center;
  column-gap: 0.5rem;
}

.astroloudMusicHeader {
  padding-top: 10rem;
  margin-bottom: 3rem;
  color: var(--lightGrey);
  text-align: center;
}
.song {
  position: relative;
}
.song::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: all 0.5s linear;
}
.song:hover::after {
  transition: all 0.4s ease-out;
  background-color: rgba(0, 0, 0, 0.5);
}
.song img {
  width: 100%;
  height: 100%;
}
.songDetails {
  padding: 1.5rem;
  position: absolute;
  top: 30%;
  left: 20%;
  transition: all 0.52s linear;
  display: none;
  opacity: 0.9;
  z-index: 200;
}
.song:hover .songDetails {
  display: flex;
}

.releaseCard img {
  width: 100%;
  cursor: pointer;
}
.loadLink {
  margin: 0 auto;
  margin-top: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 45px;
  border: 1px solid var(--lightGrey);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  transition: var(--transition);
}
.loadLink:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}

@media screen and (max-width: 768px) {
  .astroloudMusicHeader {
    margin-top: -4rem;
  }
  .songsContainer {
    grid-template-columns: repeat(2, minmax(200px, 300px));
  }
  .fourthSong {
    order: 2;
  }
}

@media screen and (max-width: 375px) {
  .songsContainer {
    grid-template-columns: 1fr;
  }
}
