.newReleaseContainer {
  padding-top: 1rem;
}
.newReleaseHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerText {
  color: var(--lightGrey);
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.directionIcons {
  display: flex;
  gap: 1rem;
}
.directionIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 3px solid var(--lightGrey);
}
.directionIconContainer i {
  color: var(--lightGrey);
  font-size: 1.5rem;
}
.releasesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.releaseCard {
  max-width: 400px;
  margin-block: 1rem;
}
.releaseCard img {
  width: 100%;
  cursor: pointer;
}

.releaseDetails {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.releaseDetails h2,
.releaseDetails h3 {
  color: var(--lightGrey);
}
.releaseDetails h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.releaseDetails h2 {
  font-size: 1.3rem;
}
.releaseDetails h3 span {
  color: var(--songTypeColor);
}
.playlistLink {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 113px;
  height: 45px;
  border: 1px solid var(--lightGrey);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  transition: var(--transition);
}
.playlistLink:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}

@media screen and (max-width: 799px) {
  .releasesContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
  }
  .releaseCard {
    /* margin-left: -15%; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .releaseCard img {
    display: inline-block;
    width: 300px;
    /* margin-left: 25%; */
  }
  .releaseDetails {
    width: 400px;
    /* margin-left: 50%; */
  }
}

@media screen and (max-width: 375px) {
  .releaseCard img {
    /* margin-left: 13%; */
  }
  .releaseDetails {
    /* margin-left: 20%; */
  }
}
@media screen and (min-width: 800px) {
  .releasesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
