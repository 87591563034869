.musicVideosSection {
  margin-block: 4.5rem;
}

.singleBigImg {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  position: relative;
  transition: background 0.5s ease-in-out;
  height: 700px;
  transition: var(--transition);
  background: url("../assets/images/wap.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.singleBigImg:hover {
  background: url("../assets/images/wap.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.singleBigImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .singleBigImg video,
.singleSmallPoster video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
} */

.singleSmallPoster {
  transition: background 0.5s ease-in-out;
  background: url("../assets/images/furniture.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 400px;
}
.singleSmallPoster:hover {
  background: url("../assets/images/furniture.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.lastImg {
  transition: background 0.5s ease-in-out;
  background: url("../assets/images/tooSoft.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.lastImg:hover {
  background: url("../assets/images/too-soft.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.singleSmallPoster {
  width: 100%;
  position: relative;
}
.singleSmallPoster img {
  height: 400px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.singleBigImg::after,
.singleSmallPoster::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: all 0.5s linear;
}
.singleBigImg:hover::after,
.singleSmallPoster:hover::after {
  transition: all 0.4s ease-out;
  background-color: rgba(0, 0, 0, 0.1);
}

.singleBigImg:hover .videoDetails,
.singleSmallPoster:hover .videoDetails {
  display: flex;
}
.videoLink a:hover {
  background: #e3e2e4;
}
.singleBigImg .videoDetails {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
}
.smallImgContainer .videoDetails {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
.videoDetails h2 {
  color: var(--whiteColor);
  font-size: 1.3rem;
  letter-spacing: 1px;
  font-family: var(--secondaryFont);
  line-height: 1.5;
  text-align: center;
  font-weight: bold;
}
.videoLink {
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.videoLink a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteColor2);
  font-family: var(--secondaryFont);
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 24px;
  transition: var(--transition);
  border: 1px solid var(--lightGrey);
  z-index: 1;
}

.singleSmallPoster img {
  cursor: pointer;
  width: 100%;
}
.musicVideosHeader {
  color: var(--lightGrey);
  font-size: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  text-transform: uppercase;
}

.viewLinkContainer {
  display: flex;
  justify-content: center;
}
.watchMoviesLink {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 40px;
  border: 1px solid var(--lightGrey);
  background: transparent;
  color: var(--lightGrey);
  font-family: var(--secondaryFont);
  cursor: pointer;
  transition: var(--transition);
}
.watchMoviesLink:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}

@media screen and (max-width: 800px) {
  .smallImgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .singleSmallPoster img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  .singleBigImg {
    height: 500px;
    object-fit: cover;
  }
  .musicVideosContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5px;
  }
  .singleBigImg {
    order: 1;
  }
  .singleSmallPoster .videoContainer {
    order: 1;
  }
  .lastImg {
    order: -6;
    margin-bottom: -1.5rem;
  }
}
@media screen and (min-width: 800px) {
  .smallImgContainer {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
  .musicVideosContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5px;
  }

  .singleSmallPoster {
    min-width: 300px;
    height: 400px;
  }
  .singleSmallPoster img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .singleSmallPoster {
    width: 100%;
  }
}
