.heroLinks {
  position: absolute;
  bottom: 4rem;
  left: 0rem;
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
  width: 100vw;
  box-sizing: border-box;
}

.heroLinks li {
  height: 50px;
  border: 1px solid var(--lightGrey);
  width: 49.5%;
}

.heroLinks li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  letter-spacing: 0.1rem;
  transition: var(--transition);
}
.heroLinks li a:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}

@media screen and (max-width: 375px) {
  .heroLinks {
    flex-direction: column;
    gap: 1.5rem;
  }
  .heroLinks li {
    width: 100%;
  }
}
