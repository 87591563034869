.privacyPolicyPage {
  min-height: 100vh;
  width: 100%;
  background: url("../assets/images/HOME.png");
}
.privacyHeader {
  color: var(--lightGrey);
  letter-spacing: 2.5px;
  text-transform: uppercase;
  font-family: var(--primaryFont);
  font-size: 2rem;
  margin-block: 1rem;
}

.privacyDetailsSection {
  padding-top: 8rem;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 15%;
}

.privacyText p {
  max-width: 800px;
  text-align: left;
  margin-top: 2rem;
  color: var(--lightGrey);
  font-family: var(--secondaryFont);
  text-transform: uppercase;
  color: var(--privacyTextColor);
  letter-spacing: 1.5px;
  font-weight: 400;
  line-height: 24px;
  font-size: 0.95rem;
}

.privacyFooter {
  margin-top: 6rem;
}

@media screen and (max-width: 768px) {
  .privacyText p {
    width: 100%;
    font-size: 0.875rem;
  }
  .privacyDetailsSection {
    margin-top: -2rem;
    padding-inline: 1rem;
  }
}
