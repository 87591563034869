.loadingPageContainer {
  background: url("../assets/images/HOME.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: var(--transition);
  object-fit: cover;
}

.loadingPage {
  width: 100%;
  min-height: 95vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoContainer {
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  margin-top: 10.5rem;
}

.scrollSpace {
  width: 100%;
  height: calc(100vh - 95vh);
  background: url("../assets/images/furniture.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: var(--transition);
  object-fit: cover;
}
.landingPageHeader {
  align-self: flex-start;
  display: flex;
  overflow-x: hidden;
  /* animation: scroll 10s linear infinite; */
  width: 100%;
}
.landingPageHeader div {
  height: 64px;
  border: 0.5px solid var(--loadingBorderColor);
  padding-inline: 3rem;
  padding-top: 1rem;
  flex-grow: 1;
}

.landingItem p {
  color: var(--loadingBorderColor);
  text-transform: uppercase;
  font-family: var(--loadingFont);
  font-size: 1.2rem;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 600;
}
.landingPageFooter {
  margin-top: 9rem;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  /* animation: scrollLeft 10s linear infinite; */
}
.landingPageFooter div {
  height: 64px;
  border: 0.5px solid var(--loadingBorderColor);
  padding-inline: 3rem;
  padding-top: 1rem;
  flex-grow: 1;
}
/* .loadingHeader,
.loadingFooter {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 64px;
  display: flex;
  flex-shrink: 0;
  border: 0.5px solid var(--loadingBorderColor);
}

.loadingHeader p,
.loadingFooter p {
  padding-top: 1rem;
  color: var(--loadingBorderColor);
  text-transform: uppercase;
  padding-inline: 3rem;
  font-family: var(--loadingFont);
  font-size: 1.2rem;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 600;
  border-right: 0.5px solid var(--loadingBorderColor);
  text-align: center;
  animation: slide 5s linear infinite;
  animation-fill-mode: forwards;
}
.logoContainer {
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
}
.loadingHeader {
  position: absolute;
  top: 0;
}
.loadingFooter {
  position: absolute;
  bottom: 3rem; */

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes scrollLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
