.musicPage {
  min-height: 100vh;
  width: 100%;
  padding-top: 5rem;
  background: url("../assets/images/HOME.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.musicVideos {
  padding-top: 4rem;
}

.pageHeader {
  margin-top: 3rem;
  color: var(--lightGrey);
  text-align: center;
}
.smallImgContainer2 {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
}
.singleSmallPoster2 {
  height: 700px;
}
.lastVideo {
  height: 700px;
}
.singleBigImg2 {
  height: 700px;
}

@media screen and (max-width: 768px) {
  .musicPage {
    margin-top: -3rem;
  }
  .singleSmallPoster2 {
    height: 500px;
  }
  .lastVideo img {
    height: 500px;
  }
  .singleBigImg2 {
    height: 500px;
  }
  .pageHeader {
    margin-top: 4rem;
  }
}
