@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@400;700&display=swap");

@import url("https://fonts.cdnfonts.com/css/rollbox");

/* TradeGothicLT */

@font-face {
  font-family: "TradeGothicLT";
  src: url("./assets/fonts/TradeGothic\ LT\ Bold\ Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TradeGothicLT";
  src: url("./assets/fonts/TradeGothic\ LT\ Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TradeGothicLT";
  src: url("./assets/fonts/TradeGothic\ LT\ Light\ Italic.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
}
:root {
  --primaryFont: "TradeGothicLT";
  --secondaryFont: "Sofia Sans", sans-serif;
  --loadingFont: "Rollbox", sans-serif;
  --lightGrey: #caccd2;
  --loadingBorderColor: #a1a2a8;
  --whiteColor: #fff;
  --footerTextColor: #f5f5f5;
  --privacyTextColor: #fbfbfb;
  --darkBg: #08090a;
  --songTypeColor: #4c8796;
  --whiteColor2: #fbfbfb;
  --blackColor: #222;
  --blackColor2: #151616;
  --lighterGrey: #a7a7a7;
  --lightBrown: #d9d9d9;
  --transition: all 0.3s ease;
  --borderRadius: 0.25rem;
  --spacing: 0.15rem;
  --errorColor: #8f0606;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}
body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1;
  font-family: var(--primaryFont);
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  font: var(--primaryFont);
  font-size: 0.875rem;
}
a {
  text-decoration: none;
  color: var(--whiteColor2);
  font-family: var(--secondaryFont);
}
li {
  list-style-type: none;
}
.showSideMenu {
  margin-right: 0;
}
.hideMenuBar {
  display: none;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
