.footerSection {
  padding-bottom: 3rem;
}
.footerDetails {
  margin-top: 3rem;
  padding-inline: 2rem;
  display: flex;
  justify-content: space-between;
}
.footerLinksContainer {
  display: flex;
  gap: 15.8rem;
}
.footerItems li {
  margin-block: 1.5rem;
}
.footerItems li a,
.footerItems li .footerLink {
  color: var(--footerTextColor);
  transition: var(--transition);
  letter-spacing: 2.5px;
  font-weight: normal;
  line-height: 24px;
  font-size: 1rem;
}
.footerItems li a:hover {
  color: var(--lightGrey);
}
.footerItems li .footerLink:hover {
  color: var(--lightGrey);
}
.copywrightSection {
  margin-top: 4rem;
  padding-inline: 2rem;
  display: flex;
  justify-content: space-between;
}
.copywrightSection p {
  color: var(--lightGrey);
  font-family: var(--secondaryFont);
  text-transform: uppercase;
  color: var(--footerTextColor);
  letter-spacing: 2.5px;
  font-weight: normal;
  line-height: 24px;
  font-size: 1rem;
}

.footerIcons {
  display: flex;
  gap: 1.5rem;
}
.footerIcons i {
  font-size: 1.5rem;
}

@media screen and (max-width: 375px) {
  .footerLinksContainer {
    gap: 1rem;
  }

  .footerItems li {
    margin-block: 0.5rem;
  }
  .footerItems li a {
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 1px;
  }
}
@media screen and (min-width: 376px) {
  .footerLinksContainer {
    gap: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .footerLinksContainer {
    width: 100%;
    justify-content: space-between;
  }

  .footerDetails {
    flex-direction: column;
    padding-inline: 1.5rem;
  }
  .footer-items {
    display: grid;
  }
  .footerLogo {
    order: 1;
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .footerLinksContainer {
    gap: 15rem;
  }
}
