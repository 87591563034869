.subscribeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.5rem;
  height: 230px;
  padding-block: 3rem;
  background: linear-gradient(268.41deg, #3e2f80 0%, #5fb2be 95.85%), #822020;
}

.subscribeContainer h3 {
  color: var(--whiteColor);
  font-family: var(--secondaryFont);
}
.subscribeFormContainer {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  height: 40px;
}
.errorText {
  color: var(--errorColor);
  margin-top: 1rem;
  font-family: var(--secondaryFont);
}
.subscribeFormContainer input {
  height: 100%;
  border: 0;
  outline: none;
  width: 350px;
  padding: 0.5rem;
  border-bottom: 1px solid var(--lightGrey);
  background: transparent;
  color: var(--whiteColor);
  font-family: var(--secondaryFont);
  text-transform: uppercase;
}
.subscribeFormContainer button {
  height: 100%;
  width: 145px;
  border: 1px solid var(--lightGrey);
  outline: none;
  background: transparent;
  color: var(--lightGrey);
  font-family: var(--secondaryFont);
  cursor: pointer;
  transition: var(--transition);
}
.subscribeFormContainer button:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}

.footerForm input::placeholder {
  color: var(--lightGrey);
  font-family: var(--secondaryFont);
}

@media screen and (max-width: 375px) {
  .subscribeFormContainer {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    padding-inline: 5%;
    margin-top: 1rem;
  }
  .subscribeInputContainer {
    width: 100%;
  }
  .subscribeInputContainer input {
    width: 100%;
    padding-left: 0.5rem;
    height: 40px;
    font-size: 12px;
    padding: 5px;
  }
  .subscribeInputContainer input::placeholder {
    padding: 2px;
    font-size: 12px;
  }
  .subscribeInputButton {
    width: 100%;
  }
  .subscribeContainer h3 {
    font-size: 0.875rem;
    text-align: center;
  }
  .errorText {
    margin-top: 4rem;
    font-size: 12px;
  }

  .subscribeContainer h3 {
    text-align: center;
  }

  .subscribeInputButton button {
    width: 100%;
    height: 40px;
    font-size: 12px;
  }
}

@media screen and (min-width: 376px) {
  .subscribeFormContainer {
    flex-direction: row;
  }
  .footerForm input {
    width: 200px;
  }
}

@media screen and (min-width: 768px) {
  .subscribeInputContainer input {
    width: 350px;
  }
}
