
.heroSection {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/images/heroBg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background 0.5s ease-in-out;
  object-fit: cover;
}
.heroSection:hover {
  background-image: url("../assets/images/furniture-home.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.homePageSections {
  background: url("../assets/images/HOME.png");
}

@media screen and (max-width: 768px) {
  .heroSection {
    width: 100%;
    background: url("../assets/images/furniture-teaser-portrait\ 1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: cover;
  }
}
