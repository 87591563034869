.desktopNav {
  border-bottom: 1px solid var(--lightGrey);
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: var(--blackColor2);
  opacity: 0.9;
  top: 0;
  width: 100vw;
  height: 80px;
  z-index: 100;
  display: none;
  transition: opacity 0.5s ease-in-out;
}
.desktopNav.hide {
  opacity: 0;
}

.navLogo {
  width: 10%;
  max-width: 120px;
  height: 100%;
  border-right: 1px solid var(--lightGrey);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navLogo > img {
  width: 60%;
  height: 70%;
}

.navLinkSection {
  display: flex;
  height: 100%;
}

.navLinks {
  display: flex;
  gap: 1rem;
  height: 100%;
  align-items: center;
  padding: 0 10px;
}

.navLinks li {
  display: flex;
}

.navLinks li a {
  letter-spacing: 0.1rem;
  font-size: 12px;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid var(--lightGrey);
  padding: 8px 23px;
  transition: var(--transition);
}
.navLinks li a:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}

.navIcons {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 10px;
  border-left: 1px solid var(--lightGrey);
}

.navIcons li {
  display: flex;
  margin: 0 15px;
}

.navIcons li a {
  font-size: 22px;
}

.twitterIcon {
  color: var(--whiteColor);
}
.youtubeIcon {
  color: var(--whiteColor);
}

@media screen and (min-width: 768px) {
  .desktopNav {
    display: flex;
  }
}

/* SideMenu */

.sideMenuContainer {
  background: url("../assets/images/HOME.png");
  padding-top: 1.5rem;
}
.sideMenu {
  background: url("../assets/images/HOME.png");
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  transition: var(--transition);
  z-index: 200;
  margin-left: 800px;
}

.sideMenu.open {
  margin-left: 0;
}

.sideMenuNav {
  margin-top: 5rem;
  padding-inline: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sideMenuHeader {
  position: fixed;
  padding-inline: 1rem;
  display: flex;
  z-index: 250;
  justify-content: space-between;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  top: 1.5rem;
  padding-right: 0.5rem;
  position: absolute;
  right: 1.2rem;
  z-index: 101;
  cursor: pointer;
}
.sideMenuBar {
  cursor: pointer;
  top: 1.5rem;
  position: fixed;
  right: 1.2rem;
  z-index: 100;
  cursor: pointer;
}

.sideMenuLogo {
  height: 40px;
}

.sideMenuLogo img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.mobileNavLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobileNavLinks li a {
  color: var(--lightGrey);
  display: inline-block;
  margin-block: 2rem;

  font-family: var(--secondaryFont);
  text-transform: uppercase;
  color: var(--footerTextColor);
  letter-spacing: 2.5px;
  font-weight: normal;
  line-height: 24px;
  font-size: 1rem;
}
.mobileNavIcons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.mobileNavIcons i {
  font-size: 2rem;
}
.sideBarForm {
  margin-top: 4rem;
  padding-inline: 1.5rem;
}
.sideMenuInput {
  width: 100%;
  height: 50px;
  padding: 1rem;
  background: transparent;
  display: inline-block;
  margin-bottom: 2rem;

  outline: none;
  border: none;
  border: 0.5px solid var(--lightGrey);
  font-family: var(--primaryFont);
  font-weight: 400;
  font-size: 0.875rem;
}
.sideMenuInput::placeholder {
  color: var(--whiteColor);
  font-weight: 300;
}
.sideBarForm button {
  width: 100%;
  height: 50px;
  outline: none;
  color: var(--whiteColor);
  transition: var(--transition);
  background: transparent;
  font-family: var(--primaryFont);
  letter-spacing: 1.2px;
  line-height: 1.5;
  cursor: pointer;
  font-size: 0.875rem;
  border: none;
  border: 0.5px solid var(--lightGrey);
  text-transform: uppercase;
}
.sideBarForm button:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}
@media screen and (max-width: 375px) {
  .sideMenu {
    min-height: 100vh;
  }
  .mobileNavLinks li a {
    margin-block: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .sideMenuContainer {
    display: none;
  }
  .sideMenuBar {
    display: none;
  }
}
