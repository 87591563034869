.servicePage {
  min-height: 100vh;
  background: url("../assets/images/HOME.png");
}

.serviceHeader {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerImg {
  display: none;
  margin-top: 6rem;
}
.headerImg img {
  height: 100px;
  width: 120px;
}
.serviceHeader h1 {
  text-align: center;
  margin-top: 1.5rem;
  color: var(--lightGrey);
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1.2;
}
.serviceHeader p {
  font-family: var(--primaryFont);
  text-align: center;
  margin-top: 2rem;
  color: var(--lightGrey);
  font-size: 1rem;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}

.serviceCard {
  padding-inline: 1rem;
  padding-block: 2.5rem;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  margin-block: 2.5rem;
}

.serviceDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.serviceDetails h2 {
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem 0;
}

.blackBg {
  background: var(--blackColor2);
  padding-block: 3.9rem;
}
.serviceCard h2 {
  color: var(--whiteColor);
}
.serviceDetails p {
  font-size: 1rem;
  font-family: var(--secondaryFont);
  line-height: 1.2;
  color: var(--lightGrey);
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.serviceDetails a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  font-size: 1rem;
  height: 50px;
  width: 180px;
  border: 1px solid var(--lightGrey);
  transition: var(--transition);
  text-transform: uppercase;
}
.serviceDetails a:hover {
  background: var(--whiteColor);
  color: var(--blackColor);
}
.astroloudDetails {
  padding: 3rem 2rem;
  background: var(--blackColor2);
}

.astroloudDetails h2 {
  font-size: 2.5rem;
  color: var(--whiteColor);
  margin-bottom: 3rem;
}
.astroloudDetails h2 span {
  background: linear-gradient(268.41deg, #836fdc 0%, #5fb2be 95.85%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.detailsText,
.detailsMiddleText {
  font-family: var(--secondaryFont);
  color: var(--lightGrey);
  line-height: 1.5;
  margin-block: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
}
.detailsMiddleText {
  margin-block: 1.3rem;
}
.astroloudTeamMember {
  display: flex;
  width: 330px;
  height: 330px;
}
.astroloudTeamMember img {
  width: 100%;
  object-fit: cover;
}
.singleMember {
  display: flex;
  gap: 1.5rem;
}
.memberInfo {
  align-self: flex-end;
}
.memberInfo p {
  margin: 0;
  color: var(--lightGrey);
}
.singleMember h4 {
  margin-top: 0.5rem;
}

.singleMember h4 {
  color: var(--whiteColor);
}
.serviceFooter {
  padding-block: 3.5rem;
}

@media screen and (max-width: 280px) {
  .astroloudDetails h2 {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .serviceHeader {
    padding-inline: 1.5rem;
  }
  .astroloudDetails h2 {
    font-size: 1.5rem;
  }
  .astroloudTeamMember {
    width: 100%;
    padding-inline: 1px;
  }
  .serviceHeader h1 {
    text-align: center;
    font-size: 1rem;
  }
  .serviceHeader p {
    text-align: center;
    line-height: 20px;
    font-size: 0.875rem;
  }
  .serviceCardImg {
    padding-inline: 1.5rem;
  }
  .serviceCardImg img {
    width: 100%;
  }

  .changedServiceDetails {
    margin-top: -2rem;
  }
  .serviceDetails {
    align-items: flex-start;
    padding-inline: 1.5rem;
  }
  .singleMember {
    flex-direction: column;
    gap: 1;
  }
  .memberInfo {
    align-self: flex-start;
  }
  .astroTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .singleMember {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-block: 1rem;
  }
  .astroloudTeamMember img {
    width: 100%;
  }
  .astroloudTeamMember {
    order: -1;
    height: 450px;
  }

  .serviceDetails p {
    line-height: 1.4;
    font-size: 0.875rem;
  }
  .detailsText,
  .detailsMiddleText {
    line-height: 1.3;
    font-size: 0.875rem;
  }
  .textTop {
    order: -1;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 769px) {
  .serviceCard {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .flexRight {
    order: 1;
  }

  .headerImg {
    display: block;
  }
  .serviceDetails {
    align-items: flex-start;
  }
  .serviceCard p {
    max-width: 30rem;
  }
  .astroloudDetails {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .memberInfo {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .astroloudDetails p {
    max-width: 35rem;
  }
}
